<template>
  <div class="tw-bg-white tw-py-8 tw-overflow-hidden">
    <FeedbackSuccess v-if="success" @close="$router.push('/')" />
    <div v-else>
      <div
        class="tw-mx-auto tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-blue-100"
      >
        <svg
          class="tw-h-6 tw-w-6 tw-text-blue-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
          ></path>
        </svg>
      </div>
      <div class="tw-mt-3 tw-text-center sm:tw-mt-5">
        <h3
          class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
          id="modal-headline"
        >
          Submit feedback or issues
        </h3>
        <div class="tw-mt-2">
          <p class="tw-text-sm tw-text-gray-500">
            Your feedback is valuable in helping improve our products.
          </p>
        </div>
      </div>
      <div class="tw-mt-12">
        <SupportForm @success="success = true"/>
      </div>
    </div>
  </div>
</template>

<script>
import SupportForm from "@/components/Help/SupportForm.vue";
import FeedbackSuccess from "@/components/Help/FeedbackSuccess.vue";

export default {
  components: {
    SupportForm,
    FeedbackSuccess,
  },
  data() {
    return {
      success: false,
    };
  },
};
</script>

<style>
</style>